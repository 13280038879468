























































































































































































import {
  computed,
  defineComponent,
  onMounted,
  Ref,
  ref
} from '@vue/composition-api';

import { i18nTxt } from '@/commons/i18n';
import Demo from '@/components/services/Demo.vue';
import QModal from '@/components/services/QModal.vue';

export default defineComponent({
  components: { Demo, QModal },
  name: 'AIWeave',
  setup(props, { emit }) {
    const isServiceSub1Show = ref(false);
    const isServiceSub2Show = ref(false);
    const isWeaveExperienceImgClicked = ref(false);
    const weaveTitle: Ref<any | null> = ref(null);
    const weaveService: Ref<any | null> = ref(null);
    const weaveSpecial: Ref<any | null> = ref(null);
    const weaveUses: Ref<any | null> = ref(null);
    const weaveExperience: Ref<any | null> = ref(null);
    const randomNum = Math.floor(Math.random() * 10);
    onMounted(() => {
      emit('emitIsHomeFocus', false);
      initObserver();
    });

    function initObserver() {
      const io = new IntersectionObserver(
        (entries) => {
          entries.map((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add('visible');
            }
          });
        },
        { threshold: 0.2 }
      );
      io.observe(weaveTitle.value);
      io.observe(weaveService.value);
      io.observe(weaveSpecial.value);
      io.observe(weaveUses.value);
      io.observe(weaveExperience.value);
    }

    const demoClass = computed(() => {
      const classList = [
        'area_preview',
        {
          demo1: !isWeaveExperienceImgClicked.value,
          'demo1-1': isWeaveExperienceImgClicked.value
        }
      ];

      const classList2 = [
        'area_preview',
        {
          demo2: !isWeaveExperienceImgClicked.value,
          'demo2-2': isWeaveExperienceImgClicked.value
        }
      ];
      return randomNum > 4 ? classList : classList2;
    });

    return {
      i18nTxt,
      isServiceSub1Show,
      isServiceSub2Show,
      isWeaveExperienceImgClicked,
      weaveTitle,
      weaveService,
      weaveSpecial,
      weaveUses,
      weaveExperience,
      demoClass
    };
  }
});
